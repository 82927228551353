import React from 'react';
import './posts.css';

class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      postMessage: ""
    }
  }

  componentDidMount() {
    this.getPostsData();
  }

  getPostsData = async () => {
    const resp = await fetch(
      "https://my-worker.andreww5.workers.dev/posts", {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }
    );
    const posts = await resp.json()
    this.setState({
      posts: posts.map(post => {
        const post_obj = JSON.parse(post);
        post_obj.key = makeid(10);
        return post_obj;
      })
    });
  }

  submitPost = async () => {
    if(!this.validatePostData()) {
      return
    }
    const new_post = {
      "title": document.getElementById("title").value,
      "username": document.getElementById("username").value,
      "content": document.getElementById("content").value
    };
    await fetch(
      "https://my-worker.andreww5.workers.dev/posts", {
        method: 'POST',
        headers: {
          'Accept': 'text/plain',
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify(new_post),
        credentials: 'include'
      }
    ).then(resp => {
      if (resp.ok) {
        new_post.key = makeid(10);
        const new_posts = [new_post, ...this.state.posts];
        const post_message = "Post submitted successfully!"
        this.setState({
          posts: new_posts,
          postMessage: post_message
        });
      }
    }).catch( error => {
      console.warn('Error', error);
      const post_message = "Falied to post. Please try again."
      this.setState({
        postMessage: post_message
      });
    });
  }

  validatePostData() {
    var ret = true;
    const input_ids = ["title", "username", "content"];
    for (let input_id of input_ids) {
      var input_elem = document.getElementById(input_id);
      if(!input_elem.value) {
        input_elem.className = "missingInput";
        ret = false;
      } else {
        input_elem.className = "";
      }
    }
    return ret;
  }

  render() {
    return (
      <div>
        <h1>SocialBook</h1>
        <div id="new_post">
          <h2>Write a new post!</h2>
          <h4 id="postMessage">{this.state.postMessage}</h4>
          <div>
            <input type="text" id="title" name="title" placeholder="Title"></input>
            <input type="text" id="username" name="username" placeholder="Username"></input>
            <br></br><br></br>
            <textarea id="content" name="content" placeholder="Write something here..."></textarea>
            <br></br>
            <input type="submit" id="submit" value="Submit" onClick={this.submitPost}></input>
          </div>
        </div>
        {this.state.posts.map((post) => (
          <div key={post.key} className="post">
            <h2>{post.title}</h2>
            <h4>{post.username}</h4>
            <p>{post.content}</p>
          </div>
        ))}
      </div>
    );
  }
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default Posts;
