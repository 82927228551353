import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "@reach/router";
import Posts from './components/posts';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Posts path="/" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
